import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";
import en from "../locales/en";
import ja from "../locales/ja";
const i18n = createI18n({
  locale: localStorage.getItem("lang") || "en",
  legacy: false,
  messages: {
    en: en,
    ja: ja,
  },
});
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/free-trail",
    name: "free-trail",
    meta: {
      isPublic: true,
      translationKey: "common.freeTrialTitle",
    },
    component: () => import("../views/FreeTrial.vue"),
  },
  {
    path: "/start-test",
    name: "start-test",
    meta: {
      isPublic: true,
      translationKey: "",
    },
    component: () => import("../views/StartTest.vue"),
  },
  {
    path: "/ans-confirmation",
    name: "ans-confirmation",
    meta: {
      isPublic: true,
      translationKey: "",
    },
    component: () => import("../views/AnswerConfirmation.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
      translationKey: "common.loginTitle",
    },
    component: () => import("../views/QmallLogin.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    meta: {
      isPublic: true,
      translationKey: "common.forgotPasswordTitle",
    },
    component: () => import("../views/ForgotPassword.vue"),
  },

  {
    path: "/signup",
    name: "signup",
    meta: {
      isPublic: true,
      translationKey: "common.signupTitle",
    },
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/csrf-issue",
    name: "csrf issue",
    meta: {
      isPublic: true,
      translationKey: "common.csrfIssueTitle",
    },
    component: () => import("../views/CsrfIssueMessage.vue"),
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    meta: {
      isPublic: true,
      translationKey: "common.termsAndCondtionsTitle",
    },
    component: () => import("../views/TermsConditions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: {
      isPublic: true,
      translationKey: "common.privacyPolicyTitle",
    },
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/result-calculation",
    name: "result-calculation",
    meta: {
      isPublic: true,
      translationKey: "common.resultCalculationTitle",
    },
    component: () => import("../views/ResultCalculation.vue"),
  },
  {
    path: "/view-commentary",
    name: "view-commentary",
    meta: {
      isPublic: true,
      translationKey: "common.checkYourAnswerTitle",
    },
    component: () => import("../views/ViewCommentary.vue"),
  },
  {
    path: "/",
    component: () => import("../BaseLayout"),
    children: [
      {
        path: "/",
        redirect: "/my-exams",
        name: "my-exams",
        meta: {
          translationKey: "common.myExamsTitle",
        },
      },
      {
        path: "/my-exams",
        name: "my-exams",
        component: () => import("../views/MyExams.vue"),
        meta: {
          translationKey: "common.myExamsTitle",
        },
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () => import("../views/ChangePassword.vue"),
        meta: {
          translationKey: "common.changePasswordTitle",
        },
      },
      {
        path: "/my-page",
        name: "my-page",
        component: () => import("../views/MyPage.vue"),
        meta: {
          translationKey: "common.myPageTitle",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/error/404"),
    meta: {
      translationKey: "common.pageNotFound",
    },
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "404",
  //   component: () => import("../views/error/NotFoundComponent"),
  // },
  {
    path: "/access-denied",
    name: "403",
    component: () => import("../views/error/403"),
    meta: {
      translationKey: "",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const selectedLanguage = localStorage.getItem("lang");
  var title = to.name.toUpperCase();
  if (window.document) {
    if (title == "HOME") {
      window.document.title = "QMALL";
    } else if (title == "START-TEST" || title == "ANS-CONFIRMATION") {
      if (JSON.parse(localStorage.getItem("selectedExamData"))) {
        let examTitle = "";
        examTitle = JSON.parse(
          localStorage.getItem("selectedExamData")
        ).examTitle;
        window.document.title = examTitle + " | QMALL";
      }
    } else {
      window.document.title =
        i18n.global.t(to.meta.translationKey).toUpperCase() + ` | QMALL`;
    }
  }
  if (from.name == undefined && to.name == "start-test") {
    localStorage.clear();
    localStorage.setItem("lang", selectedLanguage);
    sessionStorage.clear();
    next({ path: "/csrf-issue" });
  }
  if (from.name == undefined && to.name == "ans-confirmation") {
    localStorage.clear();
    localStorage.setItem("lang", selectedLanguage);
    sessionStorage.clear();
    next({ path: "/csrf-issue" });
  }
  if (
    (from.name == "ans-confirmation" && to.name == "result-calculation") ||
    (from.name == "start-test" && to.name == "result-calculation")
  ) {
    if (localStorage.getItem("qmallUserLoginToken")) {
      sessionStorage.setItem("backBtnLabel", "myExam");
    } else {
      sessionStorage.setItem("backBtnLabel", "freeTrail");
    }
  }
  if (from.name == "my-page" && to.name == "result-calculation") {
    sessionStorage.setItem("backBtnLabel", "myPage");
  }
  if (from.name == "start-test" && to.name != "ans-confirmation") {
    to = from;
  }
  if (to.matched.some((record) => !record.meta.isPublic)) {
    const tokenIsValid = await validateToken();
    // console.log(tokenIsValid);
    if (!tokenIsValid) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
  } else {
    if (to.path == "/login") {
      const tokenIsValid = await validateToken();
      if (tokenIsValid) {
        next({ path: "/" });
      }
    }
  }

  checkUserRole(to, from, next);
  next();
});

async function validateToken() {
  // console.log("Token", localStorage.getItem("qmallUserLoginToken"));
  if (localStorage.getItem("qmallUserLoginToken")) {
    // const res = await axios.post("/mbo-api/token/update");
    // if (res.status === 200) {
    return true;
    // }
  }

  return false;
}

const checkUserRole = (to, from, next) => {
  if (
    !to.meta.requiresRoles ||
    to.meta.requiresRoles.includes(localStorage.getItem("user_role"))
  ) {
    next();
  } else {
    next("/access-denied");
  }
};

export default router;
