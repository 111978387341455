<!-- @format -->

<template>
  <v-app>
    <v-main>
      <div class="font-incrementer">
        <span class="font-inc-bg">
          <v-icon @click="decreaseFontSize" class="icn">mdi-minus</v-icon>
        </span>
        <span class="font-inc-bg">
          <span @click="resetFontSize" class="icn">{{
            $t("userHomeMenu.fontIncrementorAuto")
          }}</span>
        </span>

        <span class="font-inc-bg">
          <v-icon @click="increaseFontSize" class="icn">mdi-plus</v-icon>
        </span>
      </div>
      <router-view />
      <v-dialog v-model="showDialog" persistent max-width="290">
        <v-card class="lgin">
          <v-card-title class="headline pa-4">
            {{ $t("common.information") }}
          </v-card-title>
          <v-card-text class="message pa-4">{{ message }}</v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer />
            <v-btn class="btn-success" @click="closeDialog"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v3-loading
      :active="isLoading"
      v-if="isLoading"
      loader="bars"
      color="#54BA57"
      :opacity="0"
      :lock-scroll="true"
    ></v3-loading>
    <v-snackbar v-model="isShowSnackbar" :timeout="timeout" v-if="snackbarText">
      {{ $t(snackbarText) }}</v-snackbar
    >
  </v-app>
</template>

<script setup>
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { ref, computed, watch, nextTick, onBeforeMount, inject } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { provide } from "vue";
import router from "@/router";
import moment from "moment-timezone";
/***********  Variable Declarations ***********************/
const axios = inject("axios");
const { t } = useI18n();
const store = useStore();
const timeout = ref(1000);
const showDialog = ref(false);
const fontSize = ref(16);
const plusClickCount = ref(0);
const minsClickCount = ref(0);
const plusMaxClickCount = ref(5);
const minsMaxClickCount = ref(5);
const user_details = ref(
  JSON.parse(localStorage.getItem("user_details"))
    ? JSON.parse(localStorage.getItem("user_details"))
    : {}
);
const user_system_details = ref(
  JSON.parse(localStorage.getItem("user_system_details"))
    ? JSON.parse(localStorage.getItem("user_system_details"))
    : {}
);
const userActionsInfo = ref([
  {
    id: 1,
    title: "Login",
    url: "/login",
    action: "Logged in Successfully",
  },
  {
    id: 2,
    title: "My Exams",
    url: "/my-exams",
    action: "Display the My Exams Page",
  },
  {
    id: 3,
    title: "Start Test",
    url: "/start-test",
    action: "Display the Questions list page",
  },
  {
    id: 4,
    title: "Answer confirmation",
    url: "/ans-confirmation",
    action: "Click the Finish Test button",
  },
  {
    id: 5,
    title: "Result Calculation",
    url: "/result-calculation",
    action: "Click the check your answer button",
  },
  {
    id: 6,
    title: "View Commentary",
    url: "/view-commentary",
    action: "Display the View Answer Page",
  },
  {
    id: 7,
    title: "My Page",
    url: "/my-page",
    action: "Display the My Page page",
  },
  {
    id: 8,
    title: "Home",
    url: "/",
    action: "Display the Home Page",
  },
  {
    id: 9,
    title: "Change Password",
    url: "/change-password",
    action: "Display the Change Password Fields",
  },
  {
    id: 10,
    title: "Privacy Policy",
    url: "/privacy-policy",
    action: "Display the Privacy Policy",
  },
  {
    id: 11,
    title: "Terms and Conditions",
    url: "/terms-of-service",
    action: "Display the Terms and Conditions",
  },
]);
const selectedItem = ref({});
const selectedTimezone = ref("UTC");
/***********  Computed Declarations ***********************/
const globalMessage = computed(() => {
  if (store.getters && store.getters.global) {
    return store.getters.global.message;
  } else {
    return "No message";
  }
});

const snackbarText = computed(() => {
  if (store.getters && store.getters.global) {
    return store.getters.global.snackbarText;
  } else {
    return null;
  }
});

const message = computed(() => {
  if (globalMessage.value !== null) {
    if (globalMessage.value === null) {
      return globalMessage.value.message;
    } else {
      if (globalMessage.value.code) {
        return t(globalMessage.value.code);
      }
      return globalMessage.value.message;
    }
  } else {
    return null;
  }
});

const isLoading = computed({
  get() {
    if (store.getters && store.getters.global) {
      return store.getters.global.loading;
    } else {
      return false;
    }
  },
  set(value) {
    store.dispatch("setLoading", {
      loading: value,
    });
  },
});

const isShowSnackbar = computed({
  get() {
    if (store.getters && store.getters.global) {
      return store.getters.global.snackbar;
    } else {
      return false;
    }
  },
  set(value) {
    store.dispatch("setSnackbar", {
      snackbar: value,
    });
  },
});

/***********  Watch Declarations ***********************/
watch(globalMessage, () => {
  nextTick(() => {
    openDialog();
  });
});

watch(isLoading, () => {
  nextTick(() => {
    if (store.getters && store.getters.global) {
      isLoading.value = store.getters.global.loading;
    } else {
      isLoading.value = false;
    }
  });
});

/***********  Methods Declarations ***********************/

const openDialog = () => {
  if (store.getters.global.message) showDialog.value = true;
};

const closeDialog = () => {
  store.dispatch("setGlobalMessage", {
    message: null,
  });
  showDialog.value = false;
};

//disable context menu
document.addEventListener("contextmenu", (e) => {
  e.preventDefault();
});

//disable the image drag
addEventListener("dragstart", function (e) {
  e.preventDefault();
});

onBeforeMount(async () => {
  const selectedLang = localStorage.getItem("lang") || "en";
  var bodyElement = document.body;
  if (selectedLang == "en") {
    bodyElement.classList.add("en-lang");
  } else {
    bodyElement.classList.add("ja-lang");
  }
});

// font size reset
const resetFontSize = () => {
  fontSize.value = 16;
  setBodyFontSize();
};

// font size increment
const increaseFontSize = () => {
  if (plusClickCount.value < plusMaxClickCount.value) {
    fontSize.value += 1;
    plusClickCount.value += 1;
    setBodyFontSize();
  }
};

// font size decrement
const decreaseFontSize = () => {
  if (minsClickCount.value < minsMaxClickCount.value) {
    fontSize.value -= 1;
    minsClickCount.value += 1;
    setBodyFontSize();
  }
};

// Set the font sizes for the body
const setBodyFontSize = () => {
  document.body.style.fontSize = `${fontSize.value}px`;
  const classNames = [
    "i",
    "a",
    "button",
    "div",
    "v-select",
    "v-combobox",
    ".v-list-item-title",
    "div",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "strong",
    "b",
    "p",
    "li",
    "span",
    "td",
    "th",
    "select",
    "input",
    "label",
  ];

  classNames.forEach((className) => {
    document.querySelectorAll(className).forEach((element) => {
      element.style.fontSize = `${fontSize.value}px`;
    });
  });
};

// get current date and time
const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

//record logs
const recordingLog = async (idx, URLData) => {
  user_details.value = JSON.parse(localStorage.getItem("user_details"))
    ? JSON.parse(localStorage.getItem("user_details"))
    : {};
  user_system_details.value = JSON.parse(
    localStorage.getItem("user_system_details")
  )
    ? JSON.parse(localStorage.getItem("user_system_details"))
    : {};

  if (idx >= 0) {
    selectedItem.value = userActionsInfo.value[idx];
  }
  const params = {
    ipaddress: user_details.value.ip,
    browser: user_system_details.value.browserName,
    platform: user_system_details.value.osName,
    city: user_details.value.city,
    country: user_details.value.country,
    loguserid: user_system_details.value.userId,
    logaction: selectedItem.value ? selectedItem.value.action : "",
    pagetitle: selectedItem.value ? selectedItem.value.title : "",
    pageurl: URLData ? URLData : "",
    logindate: user_system_details.value.loginDate,
  };
  const res = await axios.post(
    "qmall-api/users/userOperation/recordingLog",
    params
  );
  if (res.status == 200) {
    if (idx == 0) {
      router.push("/my-exams");
    }
    return true;
  } else {
    return false;
  }
};

// logout log
const logoutLog = async () => {
  user_details.value = JSON.parse(localStorage.getItem("user_details"))
    ? JSON.parse(localStorage.getItem("user_details"))
    : {};
  const params = {
    loguserid: user_system_details.value.userId,
    logaction: "Logged out successfully",
    logoutdate: getCurrentDateTime(),
  };
  await axios.post("/qmall-api/users/userOperation/logOutLog", params);
  // if (res.status != 200) {
  //   console.log(res);
  // }
};

// provide the functions to all the children components
provide("recordingLog", recordingLog);
provide("logoutLog", logoutLog);

//get audio file id
const getAudioFileID = (inputString) => {
  if (inputString) {
    const match = inputString.match(/\/([^\/]+)\.mp3/);
    const outputString = match ? match[1] : "No match found";
    return "audioview" + outputString;
  } else {
    return "";
  }
};
provide("getAudioFileID", getAudioFileID);

//play audio file
const playAudioFile = (id) => {
  if (id) {
    const audio = document.getElementById(id);
    if (audio) audio.play();
  } else {
    return;
  }
};
provide("playAudioFile", playAudioFile);

//getFormatedDate
const getFormatedDate = (date) => {
  // set time zone for login users
  if (localStorage.getItem("user_details")) {
    const userDetails = JSON.parse(localStorage.getItem("user_details"));
    if (userDetails.userTimezone) {
      selectedTimezone.value = userDetails.userTimezone.title
        ? userDetails.userTimezone.title
        : "UTC";
    }
  }
  // set time zone for free trail
  if (!localStorage.getItem("qmallUserLoginToken")) {
    selectedTimezone.value = JSON.parse(
      sessionStorage.getItem("selectedFreetrailTimezone")
    )
      ? JSON.parse(sessionStorage.getItem("selectedFreetrailTimezone")).title
      : "UTC";
  }
  return moment.tz(date, selectedTimezone.value).format("DD MMM YYYY HH:mm:ss");
};
provide("getFormatedDate", getFormatedDate);
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  color: #323232 !important;
}
.en-lang {
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 400;
  font-size: 0.9rem;
  color: #000;
}
.ja-lang {
  font-family: "noto-sans-cjk-jp", sans-serif !important;
  font-weight: 500;
  font-size: 0.9rem;
  color: #000;
}
.qmall-toolbar,
.ql-toolbar {
  background: #003471 !important;
  color: #ffffff;
}

.qmall-toolbar .v-toolbar-title {
  color: #ffffff;
}

.v-main {
  transition: none !important;
}

.lgin .headline {
  font-size: 24px;
  font-weight: bold;
}

.lgin .message {
  color: #dc3545;
}

.lgin .btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.language-switch .v-btn__content {
  color: #000000;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8 !important;
}

.header a {
  color: #323232;
  text-decoration: none;
  font-weight: 700;
}

.header a:hover {
  color: #2699fb;
}

.header .v-btn__content {
  font-weight: 700;
  font-size: 16px;
  padding: 0px !important;
}

.gray-bg {
  min-height: 300px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 35px 0px 20px 0px;
}

.container {
  max-width: 1200px !important;
}

.xs-d-none {
  display: none !important;
}

.md-d-block {
  display: block !important;
}

.common-btn {
  background: linear-gradient(
    90deg,
    rgba(0, 112, 209, 1) 0%,
    rgba(37, 152, 250, 1) 55%,
    rgba(37, 152, 250, 1) 100%
  ) !important;
  color: #fff !important;
}

.common-btn:hover {
  background: linear-gradient(
    90deg,
    rgba(253, 218, 12, 1) 0%,
    rgba(254, 226, 65, 1) 55%,
    rgba(254, 226, 65, 1) 100%
  ) !important;
  color: #323232 !important;
}

.img-border img {
  border: 1px solid #e8e8e8 !important;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.intro-text p {
  font-size: 20px;
}

h2.maintitle {
  font-size: 34px;
}

.intro-text h2 {
  font-size: 26px;
}

.qmal-mobile-layut img {
  max-width: 100%;
  height: 450px;
}

.m-auto .v-responsive,
.m-auto-1 {
  margin: 0 auto;
}

.featur-outerbx {
  background: #fff;
  width: 83px;
  border: 1px solid #ececec;
  padding: 5px;
  border-radius: 5px;
  margin-right: 25px;
  text-align: center;
}

.featur-outerbx i {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  padding: 10px;
  color: #2699fb;
  width: 72px;
  font-size: 3em;
}

.qmall-features p,
.qmall-features ul li {
  font-size: 13.5px;
}

.columnspace {
  padding-top: 45px;
}

.columnspace:first-child {
  margin-top: 45px;
}

.qmall-features h5 {
  color: #323232;
  font-weight: normal;
  font-size: 18px;
}

.float-right {
  float: right;
}

.free-trail h2 {
  font-size: 60px;
  color: #323232;
  font-weight: 900;
  padding-top: 85px;
  line-height: 1;
}

.home-green-bg {
  background: #08bd80;
  color: #fff;
  font-size: 30px !important;
  font-weight: 900;
  padding: 15px 0px;
}

.d-print-table-cell {
  line-height: 1;
}

.home-green-bg .v-responsive {
  flex: unset;
}

.footer-new ul li {
  list-style: none;
  display: inline-block;
  padding-right: 15px;
}

.footer-new ul li::before {
  content: "/";
  font-size: 18px;
  color: #b7b7b7;
  font: normal normal normal 18px/1 FontAwesome;
  padding-right: 15px;
}

.footer-new ul li:first-child::before {
  content: "";
}

.footer-new ul li a:hover {
  color: #2598fa;
}

.footer-new ul li a {
  color: #323232;
  text-decoration: none;
}

.footer-socialicon-new a {
  color: #2598fa;
}

.footer-socialicon-new a:hover {
  color: #0056b3;
}

.float-right .v-responsive {
  float: right;
}

.xs-d-block {
  display: none !important;
}

.v-footer {
  margin-top: auto;
}

.v-main {
  display: flex;
  flex-direction: column;
}

.v-footer {
  flex: unset !important;
}

.qmall-password .v-input__append {
  margin-inline-start: 0 !important;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}

.qmall-password {
  position: relative;
}

.w-40 {
  width: 480px !important;
}

.anchor-link a {
  color: #2598fa;
  text-decoration: none;
}

.anchor-link a:hover {
  text-decoration: none;
  color: #5a5a5a;
}

.v-data-table-footer {
  margin-top: 35px;
}

header a.subheading {
  text-transform: uppercase;
}

.qmall-table button .v-btn__content {
  letter-spacing: normal !important;
  text-transform: none;
}

.qmall-table .start-exam-btn button {
  background: linear-gradient(
    90deg,
    rgba(0, 112, 209, 1) 0%,
    rgba(37, 152, 250, 1) 55%,
    rgba(37, 152, 250, 1) 100%
  );
  padding: 0.1rem 0.7rem !important;
  color: #fff !important;
}

.qmall-table .start-exam-btn button:hover {
  color: #323232 !important;
  background: linear-gradient(
    90deg,
    rgba(253, 218, 12, 1) 0%,
    rgba(254, 226, 65, 1) 55%,
    rgba(254, 226, 65, 1) 100%
  );
}

.v-table th {
  font-weight: bold !important;
  color: #000000 !important;
}

a {
  color: #323232;
  text-decoration: none;
}

a:hover {
  color: #2598fa;
}

.start-exam-popup .v-card {
  border: 6px solid #2598fa;
  border-radius: 15px !important;
  height: 320px;
  overflow: visible !important;
}

.popup-lft-img {
  width: 385px;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.popup-lft-img img {
  border-bottom-left-radius: 10px;
}

.modal-title {
  color: #000;

  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 20px !important;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
}

.model-subtitle {
  color: #2699fb;
  font-size: 15px;
  font-weight: 700;
}

.yellow-btn button {
  color: #323232 !important;
  background: linear-gradient(
    90deg,
    rgba(253, 218, 12, 1) 0%,
    rgba(254, 226, 65, 1) 55%,
    rgba(254, 226, 65, 1) 100%
  );
  padding: 0.72em 1.8em !important;
  letter-spacing: normal;
}

.blue-btn button {
  background: linear-gradient(
    90deg,
    rgba(0, 112, 209, 1) 0%,
    rgba(37, 152, 250, 1) 55%,
    rgba(37, 152, 250, 1) 100%
  );
  color: white;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}

h1.st-ques-title {
  font-size: 1.75rem;
  line-height: 1;
}

.v-progress-linear__content {
  color: #fff !important;
}

.v-main {
  transition: none !important;
}

.question-count {
  width: 100px;
  vertical-align: middle;
  background: #08bd80;
  border-top-left-radius: 5px;
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-size: 34px;
  line-height: 1;
  padding: 10px 0px;
}

.question-descr {
  background: #f3f3f3;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px 20px 0 15px;
  font-size: 16px !important;
}
.question-descr h3,
.question-text p,
.question-text,
.questionAns .v-input {
  font-size: 16px !important;
}
.questionAns {
  padding-top: 35px;
  background: rgb(238, 238, 238);
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  );
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 35px;
}

.start-vol-icon {
  margin-top: -2px;
}

.start-vol-icon1 {
  margin-top: -4px;
}

.start-vol-icon2 {
  margin-top: 4px;
}

.start-test .v-selection-control,
.start-test .v-checkbox {
  flex: inherit;
}

.v-btn--disabled.v-btn--variant-elevated,
.v-btn--disabled.v-btn--variant-flat {
  background: #d9d9d9 !important;
  color: #6b6b6b !important;
}

.mbl-pad-rmv {
  padding-top: 25px;
  padding-top: 25px;
}

.pop-up-close {
  position: absolute !important;
  right: -16px;
  top: -16px;
  z-index: 1;
}

.drawer-close {
  position: absolute !important;
  right: 25px;
  top: 25px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  /* .profile-icon {
    font-size: 32px !important;
  } */
  h5.mbl-name {
    font-size: 15px !important;
    text-transform: uppercase !important;
    font-weight: 700;
  }

  .mbl-nav a {
    font-weight: 700;
    font-style: normal;
    color: #323232;
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px 0px;
    margin: 0px !important;
    width: 100%;
  }

  .mbl-nav a.v-list-item {
    display: flex !important;
    align-items: center;
  }

  .mbl-nav .v-list-item .v-list-item-title {
    color: #323232;
    font-size: 16px;
    font-weight: 700;
  }

  .mbl-nav .v-list-item {
    width: 100% !important;
  }

  .popup-content-scrol {
    overflow-y: auto;
    height: 260px;
  }

  .start-exam-popup .v-col-6 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .popup-lft-img {
    position: static !important;
    width: 100%;
  }

  .start-exam-popup .v-card {
    height: 600px !important;
  }

  .start-exam-popup .mbl-col-lay {
    flex-direction: column;
  }

  .your-ans-btn {
    width: 100%;
    margin-top: 25px;
  }

  .your-ans-btn button {
    width: 100%;
  }

  .pre-next-btn button {
    width: 47%;
  }

  .mbl-pad-rmv {
    padding-top: 0px !important;
    padding-top: 20px !important;
  }

  .start-btn-box.scroll-triggered-class {
    background: #fff !important;
    position: static;
  }

  .start-btn-box {
    background: #fff !important;
    position: sticky;
    top: 60px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    z-index: 1;
  }

  .start-test audio {
    width: 100% !important;
  }

  .start-test iframe {
    width: 100% !important;
  }

  .w-40 {
    width: 100% !important;
  }

  .container {
    max-width: 100% !important;
  }

  .xs-d-block {
    display: block !important;
  }

  .md-d-block {
    display: none !important;
  }

  .v-toolbar__content {
    height: 60px !important;
    background: #2598fa !important;
  }

  .v-main {
    --v-layout-top: 60px !important;
  }

  .gray-bg .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  header .v-app-bar-nav-icon i {
    color: #fff !important;
    font-size: 34px !important;
    margin-right: 10px;
  }

  .intro-text {
    margin-top: 30px;
  }

  .py-15.img-border {
    padding-bottom: 0px !important;
  }

  .columnspace:first-child {
    margin-top: 0;
  }

  .columnspace {
    padding-top: 0px;
    width: 100%;
  }

  .columnspace1 {
    width: 100%;
  }

  .baner-text-n h2 {
    font-size: 20px;
    padding: 0px;
  }

  .qmall-features .v-row .v-col {
    flex-basis: unset;
  }

  .columnspace1 {
    padding-top: 35px;
  }

  .qmall-features .intro-text {
    margin-top: 0px;
  }

  .qmall-features .v-responsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .free-trail h2 {
    padding-top: 5px;
    font-size: 30px;
  }

  .home-green-bg .m-auto-1.d-flex {
    display: block !important;
    text-align: center;
  }

  .home-green-bg .v-responsive {
    margin: 0 auto;
    margin-inline-end: inherit !important;
    margin-bottom: 25px;
  }

  .footer-new .d-flex.justify-space-between {
    display: block !important;
  }

  .footer-new .footer-menu-new {
    padding: 25px 0px;
  }

  .free-trail .v-row.pt-10 {
    padding-top: 0px !important;
  }

  .my-exams-table tr th:nth-child(1),
  .my-exams-table tr th:nth-child(2),
  .my-exams-table tr th:nth-child(3) {
    display: none;
  }

  .my-exams-table tr td:nth-child(1),
  .my-exams-table tr td:nth-child(2),
  .my-exams-table tr td:nth-child(3) {
    display: none;
  }

  .my-exams .v-col {
    flex-basis: inherit;
  }

  .v-progress-linear {
    border: 2px solid #fff;
  }

  .v-progress-linear__background {
    opacity: 0.5 !important;
    background: #fff !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .baner-text-n .img-border .v-col {
    padding: 12px !important;
  }

  .columnspace:first-child {
    margin-top: 0;
  }

  .columnspace {
    padding-top: 0px;
    width: 100%;
  }

  .columnspace1 {
    padding-top: 35px;
  }

  .free-trail h2 {
    font-size: 40px;
  }

  .home-green-bg .m-auto-1.d-flex {
    display: block !important;
    text-align: center;
  }

  .home-green-bg .v-responsive {
    margin: 0 auto;
    margin-inline-end: inherit !important;
    margin-bottom: 10px;
  }

  .footer-new .d-flex.justify-space-between {
    display: block !important;
  }

  .footer-new .footer-menu-new {
    padding: 25px 0px;
  }

  .free-trail .v-row.pt-10 {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .baner-text-n .img-border .v-col {
    padding: 12px !important;
  }
}

.font-incrementer {
  /*border: 1px solid #000;*/
  width: 70px;
  position: fixed;
  right: -3px;
  border-top-left-radius: 5px;
  padding: 7px 15px;
  text-align: center;
  z-index: 1020;
  top: 42%;
}

.font-inc-bg {
  background: #fff;
  width: 36px;
  border: 1px solid #ececec;
  padding: 2px;
  border-radius: 5px;
  display: block;
  margin: 8px;
}

.font-inc-bg .icn {
  padding: 0px 8px;
  /* display: block; */
  /* margin: 8px; */
  background: #08bd80;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  /* vertical-align: middle; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
}

.font-inc-bg .icn:hover {
  text-decoration: none;
  background: #2699fb;
}

.nav-bar-stle.router-link-active {
  color: #2699fb !important;
  border-bottom: 2px solid #2699fb;
}

img {
  -webkit-user-drag: none;
}

.prgrss-br {
  border-radius: 3px !important;
  height: 30px !important;
}
.table-fourth-col-resize td:nth-child(4) {
  word-break: break-all !important;
}
</style>
