/** @format */

import * as Vue from "vue";
import axios from "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createI18n } from "vue-i18n";
import en from "./locales/en";
import ja from "./locales/ja";
import VueApexCharts from "vue3-apexcharts";
import VueAxios from "vue-axios";
import constant from "./config/constant";
import Loading from "vue3-loading-overlay";
import { vue3Debounce } from "vue-debounce";
loadFonts();
if (!localStorage.getItem("lang") || localStorage.getItem("lang") == "null") {
  const browserLanguage = navigator.language;
  if (browserLanguage == "ja" || browserLanguage == "ja-JP") {
    localStorage.setItem("lang", "ja");
  } else {
    localStorage.setItem("lang", "en");
  }
}
const i18n = createI18n({
  locale: localStorage.getItem("lang") || "en",
  legacy: false,
  messages: {
    en: en,
    ja: ja,
  },
});
const app = Vue.createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(VueApexCharts);

app.config.globalProperties.$constant = constant;
app.component("apex-chart", VueApexCharts);
app.component("v3-loading", Loading);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios); // provide 'axios'
app.directive("debounce", vue3Debounce({ lock: true }));
app.mount("#app");
